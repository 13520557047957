import "./styles.css";
import ReCAPTCHA from "react-google-recaptcha";

function onChange(value) {
  if (typeof Captcha !== "undefined") {
    Captcha.postMessage(value);
  } else if (window.parent) {
    window.parent.postMessage(value, "*");
  }
}

export default function App() {
  return (
    <ReCAPTCHA
      sitekey="6LdD-GYcAAAAAK2site1azLgFQAjl1iEszc2QLL6"
      onChange={onChange}
    />
  );
}
